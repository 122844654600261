<template>
  <v-container>
    <div class="d-flex justify-end">
      <v-btn small outlined @click="showAddDialog = true">
        Add a league
        <v-icon class="ml-3">mdi-plus</v-icon>
      </v-btn>
      <v-btn class="ml-2" small outlined @click="show_archived = !show_archived">
        {{ show_archived ? "Hide archived" : "Show archived" }}
        <v-icon class="ml-3">mdi-archive-outline</v-icon>
      </v-btn>
    </div>
    <div class="mt-5">
      <v-sheet 
        v-if="loading_leagues" 
        class="d-flex justify-center pt-5"
      >
        <v-progress-circular indeterminate></v-progress-circular>
      </v-sheet>
      <div
        style="display: flex; flex-direction: row; vertical-align: center; cursor: pointer; border: 1px solid grey; border-radius: 10px; justify-content: space-between;"
        class="mt-2"
        @click="$router.push(`/tiimi-admin/leagues/${league.id}/games`)"
        v-for="league in visible_leagues"
        :key="league.id + 'league'"
      >
        <v-card-title :class="{'grey--text': league.archived}">
          {{league.league_name}}
          <v-icon class="ml-4" v-if="league.archived">mdi-archive-outline</v-icon>
        </v-card-title>
        <v-menu offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              style="align-self: center;" text fab small
              @click.stop=""
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group>
              <v-list-item @click="setArchived(league.id, !league.archived)">
                <v-list-item-icon>
                  <v-icon>mdi-archive</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ !league.archived ? 'Archive' : 'Unarchive' }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
    </div>
    <v-dialog
      v-model="showAddDialog"
      @click:outside="showAddDialog = false"
      max-width="700"
    >
      <v-card>
        <v-card-text>
          <v-card-title>Create league</v-card-title>
          <v-form>
            <v-text-field label="League name" solor>
            </v-text-field>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    showAddDialog: false,
    show_archived: false
  }),
  created() {
    // this.initLeagues()
  },
  methods: {
    ...mapActions('admin', [
      'initLeagues',
      'updateLeague'
    ]),
    ...mapActions('noti', [
      'success', 
      'error'
    ]),
    setArchived(league_id, archived) {
      this.updateLeague({
        id: league_id, 
        archived
      })
      .then(() => {
        this.success('league updated')
      })
      .catch(e => {
        this.error(e)
      })
    }
  },
  computed: {
    ...mapGetters('admin', [
      'leagues',
      'games',
      'loading_leagues'
    ]),
    visible_leagues() {
      return this.show_archived ? this.leagues : this.leagues.filter(l => !l.archived)
    }
  }
}
</script>